(function($){

    /**
     * initializeBlock
     *
     * Adds custom JavaScript to the block HTML.
     *
     * @date    15/4/19
     * @since   1.0.0
     *
     * @param   object $block The block jQuery element.
     * @param   object attributes The block attributes (only available when editing).
     * @return  void
     */
    var initializeBlock = function( $block ) {
        //$block.find('img').doSomething();
        console.log("BLOCK | Init | ", "Brands Slider")

        if($('#acf-posts-brands-slider .glide .glide__slide').length){
            new Glide('#acf-posts-brands-slider .glide',{
                type: 'carousel',
                //focusAt: '0',
                perView: 5,
                startAt: 0,
                bound: true,
                //gap: 20,
                autoplay: 7000,
                hoverpause: true,
                rewind:true,
                animationDuration: 1500,
                breakpoints: {
                800: {
                    perView: 1
                }
                }
            }).mount()
        }



    }

    // Initialize each block on page load (front end).
    $(document).ready(function(){
        $('#acf-posts-brands-slider').each(function(){
            initializeBlock( $(this) );
        });
    });
    // Initialize dynamic block preview (editor).
    if( window.acf ) {
        //console.log("Window ACF")
        window.acf.addAction( 'render_block_preview', initializeBlock );
    }

})(jQuery);